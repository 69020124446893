import React from 'react';
import holdingphone from './../../assets/images/holdingphone.png';
import Button from '../Base/Button';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';

const PlansForEverybody = () => {
  const navigate = useNavigate();
  return (
    <div className="wrapper">
      {' '}
      <div>
        <h1 className="font-bold text-3xl xs:text-4xl xs:leading-[4rem] md:text-[57px] text-center ">
          Custom solutions for both
          <span className="text-primary"> online and offline merchants.</span>
        </h1>
        <p className="text-base xs:text-lg md:text-[22px] md:leading-9 text-center font-medium py-5">
          We have a plan that suits your business type
        </p>
      </div>
      <div className="flex flex-col items-center gap-24 mt-20 md:flex-row">
        <div className="flex justify-center mb-10">
          <ReactPlayer
            url="https://www.youtube.com/embed/ArRVV-QjPHE"
            // width="100%"
            // height="100%"
            playing
            loop
            // muted
            config={{
              file: {
                attributes: {
                  preload: 'auto',
                },
              },
            }}
          />
        </div>

        <div className="flex flex-col justify-between gap-16 md:w-6/12">
          <div className="">
            <h5 className="text-4xl font-bold">
              For
              <span className="text-primary"> Online Merchants</span>
            </h5>
            <p className="py-5 text-lg">
              Unlock the potential of our API,SDK and utilize our payment
              gateway as your go-to payment solution. Seamlessly send payment
              requests to your customers online and effortlessly receive
              payments, making transactions a breeze.
            </p>
            <Button variant="outline" onClick={() => navigate('/api-sdk')}>
              Read more
            </Button>
          </div>
          <div className="">
            <h5 className="text-4xl font-bold">
              For
              <span className="text-primary"> Physical Merchants</span>
            </h5>
            <p className="py-5 text-lg">
              Easily receive and accept payment requests from your favorite
              physical merchants by simply sharing your personalized QR code or
              Tag.
            </p>
            <Button
              variant="outline"
              onClick={() => navigate('/merchants-dashboard')}
            >
              Read more
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlansForEverybody;
