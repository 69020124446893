import { createBrowserRouter } from 'react-router-dom';
import DefaultLayout from '../layouts/DefaultLayout';
import Home from '../pages/home';
import Business from '../pages/business';
import About from '../pages/about';
import FAQ from '../pages/faq';
import MerchantsDashboardProduct from '../pages/products/merchantsdashboard';
import ForeignAccountsProduct from '../pages/products/foreignaccounts';
import ApiSdkProduct from '../pages/products/apiandsdk';
import PrivacyPolicy from '../pages/privacy-policy';
import Terms from '../pages/terms';
import ErrorElement from '../components/Base/ErrorElement';

export const routes = createBrowserRouter([
  {
    element: <DefaultLayout />,
    errorElement: <ErrorElement />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/business',
        element: <Business />,
      },
      {
        path: '/about',
        element: <About />,
      },
      {
        path: '/privacy-policy',
        element: <PrivacyPolicy />,
      },
      {
        path: '/terms',
        element: <Terms />,
      },
      {
        path: '/faq',
        element: <FAQ />,
      },
      {
        path: '/foreign-accounts',
        element: <ForeignAccountsProduct />,
      },
      {
        path: '/api-sdk',
        element: <ApiSdkProduct />,
      },
      {
        path: '/merchants-dashboard',
        element: <MerchantsDashboardProduct />,
      },
    ],
  },
  {},
]);
