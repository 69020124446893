import React from 'react';
import Header from '../../components/Pages/Header';
import ProductDetails from '../../components/Pages/ProductDetails';
import DownloadApp from '../../components/Pages/DownloadApp';
import FAQS from './../../data/Faq.json';
import SingleFaq from '../../components/Pages/SingleFaq';
const ForeignAccountsProduct = () => {
  const faqs = FAQS.FAQs['Foreign Accounts'];
  const details = [
    {
      header: "EFG Pay's Foreign Accounts simplify payments for customers",
      description:
        'Fund wallets in multiple currencies (NGN, USD, EUR, GBP) and transact seamlessly with partner merchants worldwide.',
    },
    {
      header: "Security is paramount with EFG Pay's Foreign Accounts.",
      description:
        ' Advanced encryption safeguards transactions. Versatile wallet stores funds in preferred currencies for convenient payments.',
      isRight: true,
    },
    {
      header: "EFG Pay's Foreign Accounts open a world of opportunities.",
      description:
        'Access a diverse network of partner merchants, enjoy exclusive rewards, and revolutionize your payment experience globally.',
    },
  ];
  return (
    <section>
      <Header
        isProduct
        productHeader="Foreign Accounts "
        productDescription="Seamlessly fund your wallet in multiple currencies, transact effortlessly with global merchants, and embrace endless opportunities with EFG Pay's Foreign Accounts. Simplify payments, expand your horizons, and experience a borderless payment ecosystem"
      />
      <ProductDetails
        title="Global wallet, Seamless Payments"
        description="Enjoy Free Virtual Accounts in NGN, USD, EUR, GBP to fund your wallet and make hassle-free payments."
        details={details}
        product="foreign accounts"
      />
      <div className="flex flex-col gap-10 mt-10 wrapper xl:w-7/12 lg:w-9/12">
        {faqs.map((faq, index) => (
          <SingleFaq key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
      <DownloadApp />
    </section>
  );
};

export default ForeignAccountsProduct;
