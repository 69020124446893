import React from 'react';
import DownloadApp from '../components/Pages/DownloadApp';
import AllFaqs from '../components/Pages/AllFaqs';
import holdingphone from './../assets/images/holdingphone.png';
import ContactUsForm from '../components/Pages/ContactUsForm';
const Faq = () => {
  return (
    <section>
      <div className="bg-[#F3F4F8] p-10 sm:p-20">
        <div className="mt-20 text-center">
          <h1 className="text-3xl xs:text-4xl lg:text-[57px]  lg:leading-[4.5rem] font-bold">
            Frequently Asked Questions
            <br />
            (FAQs)
          </h1>
          <p className="pt-6 text-base lg:text-xl lg:px-52">
            Your secure, user-friendly payment solution. Swift, hassle-free
            payments online and at physical stores. Simplify transactions, enjoy
            an enhanced payment experience!
          </p>
        </div>
      </div>

      <div className="my-20">
        <AllFaqs />
      </div>

      <div className="flex flex-col justify-between mt-32 lg:gap-20 wrapper md:flex-row n">
        <div className="xl:w-6/12 md:w-9/12 ">
          <h5 className=" text-primary opacity-80 text-3xl xs:text-4xl md:text-[57px]  md:leading-[4rem] font-bold">
            Contact Us
          </h5>
          <ContactUsForm />
        </div>

        <div className="relative hidden  z-40 px-8 py-4 rounded-lg bg-primarylight sm:w-[25rem] w-auto h-auto sm:h-[35rem] lg:flex items-center">
          <img
            src={holdingphone}
            alt="a man holding a phone with the app"
            className="object-cover w-full "
          />
        </div>
      </div>
      <DownloadApp />
    </section>
  );
};

export default Faq;
