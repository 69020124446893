interface Props {
  header: string;
  content: string;
  content2?: string;
}

const Content = ({ header, content, content2 }: Props) => {
  return (
    <div className="my-5">
      <h2 className="font-semibold md:text-lg"> {header}</h2>
      <p className="text-base">{content}</p>
      <p className="text-base">{content2}</p>
    </div>
  );
};
export default Content;
