import React from 'react';
// import Button from '../Base/Button';
// import PaymentRequestModal from './JoinWaitlistModal';
// import Modal from '../Base/Modal';
// import JoinWaitlistButton from './JoinWaitlistButton';

const JoinWaitlist = () => {
  return (
    <div className="mt-20 wrapper">
      {/* <div className="flex flex-col items-center justify-between gap-10 sm:flex-row sm:gap-20">
        <h1 className="text-2xl  relative z-50  md:text-[42px]  md:leading-[4rem] text-center sm:text-left font-bold">
          Have access to a stress free payment solution for your business
        </h1>{' '}
        <JoinWaitlistButton />
      </div> */}
      {null}
    </div>
  );
};

export default JoinWaitlist;
