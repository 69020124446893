import React, { Fragment, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ChevronDownIcon, XMarkIcon } from '../assets/icons/icons';
import { useApp } from '../context/AppContext';
import products from '../data/products';
import { motion } from 'framer-motion';

const MobileMenu = () => {
  const { setMobileMenu, mobileMenu } = useApp();
  const [showProducts, setShowProducts] = useState(false);
  return (
    <nav className="mt-[3.4rem] py-10 bg-white top-0 h-screen   z-20 absolute w-full duration-500 overflow-y-scroll p-2 bg-white`">
      <div className="h-screen overflow-y-scroll">
        <div
          onClick={() => setMobileMenu(false)}
          className="flex justify-center cursor-pointer hover:opacity-70"
        >
          <XMarkIcon color="#09066B" />
        </div>
        <div className="flex flex-col items-center justify-between gap-20 pt-20 text-sm font-semibold uppercase sm:text-sm text-primary">
          <div
            onClick={() => setShowProducts(!showProducts)}
            className="cursor-pointer"
          >
            <div className="flex items-center justify-center gap-2 ">
              <span> Products</span>
              <ChevronDownIcon color="#09066B" />
            </div>
            {showProducts && (
              <motion.div
                onHoverStart={() => setShowProducts(true)}
                onHoverEnd={() => setShowProducts(false)}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className={`  bg-white p-5  w-auto `}
              >
                <div className="flex flex-col">
                  {products.map((product, index) => (
                    <Link
                      key={index}
                      to={product.link}
                      className="flex items-center gap-3 p-3 rounded-lg cursor-pointer hover:bg-primarylight"
                    >
                      <div className="w-9/12">
                        <p className="text-sm font-semibold">{product.title}</p>
                        <p className="text-xs">{product.description}</p>
                      </div>
                    </Link>
                  ))}
                </div>
              </motion.div>
            )}
          </div>
          <div className="cursor-pointer" onClick={() => setMobileMenu(false)}>
            Developers (Coming Soon)
          </div>
          <div className="cursor-pointer" onClick={() => setMobileMenu(false)}>
            <Link to="/about">About</Link>
          </div>
          <div className="cursor-pointer" onClick={() => setMobileMenu(false)}>
            <Link to="/faq">FAQ</Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default MobileMenu;
