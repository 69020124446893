import React from 'react';
import Header from '../../components/Pages/Header';
import ProductDetails from '../../components/Pages/ProductDetails';
import DownloadApp from '../../components/Pages/DownloadApp';
import FAQS from './../../data/Faq.json';
import SingleFaq from '../../components/Pages/SingleFaq';
const MerchantsDashboardProduct = () => {
  const faqs = FAQS.FAQs['For Merchants'];
  const details = [
    {
      header: 'Seamless Withdrawals',
      description:
        'Merchants using EFG Pay can easily withdraw their funds to their preferred bank accounts. Enjoy hassle-free withdrawals, empowering merchants with financial freedom and control over their funds.',
    },
    {
      header: 'Enhanced Efficiency.',
      description:
        'Our Merchants Dashboard allows merchants to create and manage multiple branches, staff accounts, and custom roles seamlessly. Streamline business processes, optimize productivity, and focus on growing their businesses with ease.',
      isRight: true,
    },
    {
      header: 'Simplify Processes',
      description:
        'EFG Pay`s Merchants Dashboard provides a centralized hub for merchants to efficiently manage their operations. With a user-friendly interface, merchants can oversee transactions, track payment requests, and gain insights into business performance from one intuitive platform.',
    },
  ];
  return (
    <section>
      <Header
        isProduct
        productHeader="EFG Pay: Empower Merchant Financial Freedom"
        productDescription="Access comprehensive dashboard for both Online and Physical merchants, simplifying business operations."
      />
      <ProductDetails
        title="Efficient Business Management"
        description="EFG Pay's Merchants Dashboard provides a centralized hub for merchants to efficiently manage their operations. With a user-friendly interface, merchants can oversee transactions, track payment requests, and gain insights into business performance from one intuitive platform."
        details={details}
        product="the Merchants Dashboard"
      />
      <div className="flex flex-col gap-10 mt-10 wrapper xl:w-7/12 lg:w-9/12">
        {faqs.map((faq, index) => (
          <SingleFaq key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
      <DownloadApp />
    </section>
  );
};

export default MerchantsDashboardProduct;
