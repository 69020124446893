import React from 'react';
interface Props {
  title?: string;
  text?: string;
  color?: string;
  image?: any;
}
const FeatureCard = ({
  text = ' Add funds to your account and pay for goods by just showing the sales attendant your unique QR code.',
  title = 'Epic Shopping, No Fuss',
  color = '#FFF5DA',
  image,
}: Props) => {
  return (
    <div className="p-5 md:p-10 lg:p-12 rounded-xl ">
      <div
        className="w-20 my-3 h-20 rounded-[50%] flex items-center justify-center"
        style={{ backgroundColor: color }}
      >
        <img src={image} alt="app logo" />
      </div>
      <div>
        <h4 className="mt-3 text-lg md:text-4xl lg:text-[40px] font-bold md:leading-10">
          {title}
        </h4>
        <p className="text-[#323232] md:text-lg mt-5 capitalize">{text}</p>
      </div>
    </div>
  );
};

export default FeatureCard;
