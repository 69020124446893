import React, { useState } from 'react';

interface Props {
  label?: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  type?: string;
  required?: boolean;
  isPassword?: boolean;
  value?: string;
  isCoupon?: boolean;
  handleCodeChange?: () => void;
  name?: string;
}
const Input = ({
  required,
  type = 'text',
  label,
  placeholder,
  onChange,
  value,
  name,
  ...props
}: Props) => {
  return (
    <div className="relative z-10 w-full">
      <div className="flex flex-col space-y-1">
        <label className="text-xs text-left">{label}</label>
        <input
          type={type}
          className="w-full px-4 py-3 mb-4 bg-[#F3F4F8] rounded-xl  focus:border-primary focus:outline-none focus:shadow-outline placeholder:text-sm text-dark"
          placeholder={placeholder}
          required={required}
          onChange={onChange}
          name={name}
          value={value}
          {...props}
        />
      </div>
    </div>
  );
};

export default Input;
