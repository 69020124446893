import React from 'react';
import JoinWaitlist from '../components/Pages/JoinWaitlist';
import DownloadApp from '../components/Pages/DownloadApp';
import magnus from './../assets/images/magnus.png';
import ike from './../assets/images/ike.png';
const About = () => {
  return (
    <section>
      <div className="bg-[#F3F4F8] p-10 sm:p-20">
        <div className="mt-20 text-center">
          <h1 className="text-3xl xs:text-4xl lg:text-[57px]  lg:leading-[4.5rem] font-bold">
            Unlock the power of
            <span className="px-2 text-primary opacity-80 whitespace-nowrap">
              efficient <br /> payments
            </span>
            with EFG Pay
          </h1>
          <p className="pt-6 text-base lg:text-xl">
            Your secure, user-friendly payment solution. Swift, hassle-free
            payments online and at <br /> physical stores. Simplify
            transactions, enjoy an enhanced payment experience!
          </p>
        </div>
      </div>
      <div className="grid gap-10 mt-20 xs:grid-cols-2 wrapper">
        <div className="bg-[#EFEEFD] py-6 md:py-12 px-5 md:px-10 rounded-xl">
          <h3 className="font-bold text-3xl lg:leading-10 lg:text-4xl lg:text-[42px]">
            Our
            <span className="text-primary"> Mission</span>{' '}
          </h3>
          <p className="pt-4 md:text-xl">
            Our mission is to revolutionize the payment landscape through
            cutting-edge technology, delivering a comprehensive and
            user-friendly platform that simplifies transactions and enhances
            financial experiences for merchants and customers worldwide
          </p>
        </div>
        <div className="bg-[#EFEEFD] py-6 md:py-12 px-5 md:px-10 rounded-xl">
          <h3 className="font-bold text-3xl lg:leading-10 lg:text-4xl lg:text-[42px]">
            Our
            <span className="text-primary"> Vision</span>{' '}
          </h3>
          <p className="pt-4 md:text-xl">
            To empower businesses and consumers with innovative payment
            solutions, fostering a seamless, secure, and inclusive financial
            ecosystem for a better tomorrow.
          </p>
        </div>
      </div>

      <div className="mt-20">
        <div>
          <h1 className="font-bold text-3xl xs:text-4xl xs:leading-[4rem] md:text-[57px] text-center ">
            Meet the <span className="text-primary">Team</span>
          </h1>

          <div className="flex justify-center gap-3 xs:gap-20 py-14">
            <div className="flex flex-col items-center gap-2">
              <img
                src={magnus}
                alt="CEO"
                className="object-cover rounded-[50%] w-32 h-32 xs:h-52 xs:w-52"
              />
              <div>
                <h6 className="font-medium text-center xs:text-xl">
                  Magnus chukwuekezie
                </h6>
                <p className="text-center text-[#505050] text-sm xs:text-base">
                  Group Head at EFG
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center gap-2">
              <img
                src={ike}
                alt="CEO and co-founder"
                className="object-cover rounded-[50%] w-32 h-32 xs:h-52 xs:w-52"
              />
              <div>
                <h6 className="font-medium text-center xs:text-xl">
                  Ikeoluwa Oloruntoba
                </h6>
                <p className="text-center text-[#505050] text-sm xs:text-base">
                  Co-Founder & CEO
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DownloadApp />
      <JoinWaitlist />
    </section>
  );
};

export default About;
