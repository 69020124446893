/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { XMarkIcon } from '../../assets/icons/icons';
import Input from '../Base/Input';
import Button from '../Base/Button';
import Success from './../../assets/images/Success.svg';
import axiosInstance from '../../api/axios';

interface Props {
  setIsOpen: () => void;
}

const JoinWaitlistModal = ({ setIsOpen }: Props) => {
  const [companyName, setCompanyName] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [successful, setSuccessful] = useState(false);
  useEffect(() => {
    if (companyName && contactEmail && companyName) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  }, [companyName, contactName, contactEmail]);

  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const makeRequest = async () => {
    setSubmitting(true);
    try {
      const body = {
        company_name: companyName,
        contact_email: contactEmail,
        contact_name: contactName,
      };
      await axiosInstance.post(`setup/add-to-waitlist`, body, options);
      setIsOpen();
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <div className="gap-10 mx-auto h-fit ">
      {!successful ? (
        <div className="flex flex-col w-full gap-10 px-3 py-8 bg-white md:px-8">
          <div className="bg-[#F3F4F8] p-5">
            <div className="flex justify-end">
              <span
                onClick={setIsOpen}
                className="cursor-pointer bg-[#151411] bg-opacity-20 p-3 rounded-[50%]"
              >
                <XMarkIcon />
              </span>
            </div>
            <div>
              <h6 className="text-xl font-bold text-center md:text-4xl">
                Join the
                <span className="pl-1 text-primary opacity-80 whitespace-nowrap">
                  Waitlist
                </span>
              </h6>
              <p className="pt-4 text-sm text-center md:text-base">
                🚀 Join the Waitlist for EFG Pay! Experience seamless payments
                online & in stores. Swift, hassle-free transactions in a secure
                app. Don't miss out - secure your spot now! 🎉
              </p>
            </div>
          </div>

          <div className="flex flex-col w-full gap-10 px-3 py-8 bg-white md:px-8">
            <Input
              label="Name of company"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              placeholder="Enter your company’s name"
            />
            <Input
              label="Contact Name"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
              placeholder="Enter your name"
            />
            <Input
              label="Contact Email Address"
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
              placeholder="Enter your contact email address"
            />

            <div className="mt-4">
              <Button
                full
                onClick={makeRequest}
                variant={isAllSelected ? 'primary' : 'gray'}
                disabled={!isAllSelected || submitting}
                isSubmitting={submitting}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="p-3">
          <div className="flex flex-col items-center gap-3 pt-20">
            <img src={Success} alt="" />

            <p className="text-2xl font-bold text-center">Details added</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default JoinWaitlistModal;
