import React from 'react';
import Input from '../Base/Input';
import Button from '../Base/Button';
import TextArea from '../Base/TextArea';
import axiosInstance from '../../api/axios';
import { Field, Form, Formik } from 'formik';
import { contactUsValidationSchema } from '../../interfaces/forms';
import InputValidationError from '../Base/InputValidationError';

const ContactUsForm = () => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return (
    <Formik
      initialValues={{
        name: '',
        phone_number: '',
        email: '',
        message: '',
      }}
      validationSchema={contactUsValidationSchema}
      onSubmit={async (data, { setSubmitting }) => {
        try {
          setSubmitting(true);
          await axiosInstance.post(`setup/add-to-contact`, data, options);
          setSubmitting(false);
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, errors, touched, handleChange, values }) => (
        <Form>
          <div className="flex flex-col w-full gap-10 py-8 bg-white ">
            <div className="w-full">
              <Field
                name="name"
                as={Input}
                label="Full Name"
                placeholder="Enter your name"
              />
              {errors.name && touched.name ? (
                <InputValidationError text={errors.name} />
              ) : null}
            </div>
            <div className="w-full">
              <Field
                name="phone_number"
                as={Input}
                label="Phone Number"
                placeholder="Enter your name"
              />
              {errors.phone_number && touched.phone_number ? (
                <InputValidationError text={errors.phone_number} />
              ) : null}
            </div>

            <div className="w-full">
              <Field
                name="email"
                as={Input}
                label="Email Address"
                type="email"
                placeholder="Enter your email address"
              />
              {errors.email && touched.email ? (
                <InputValidationError text={errors.email} />
              ) : null}
            </div>

            <div className="w-full">
              <Field name="message" as={TextArea} label="Message" />
              {errors.email && touched.email ? (
                <InputValidationError text={errors.email} />
              ) : null}
            </div>

            <div className="w-40 mt-4">
              <Button full type="submit" isSubmitting={isSubmitting}>
                Submit
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ContactUsForm;
