import React from 'react';
import Content from '../components/Pages/Content';

const PrivacyPolicy = () => {
  return (
    <div>
      {' '}
      <div className="bg-[#F3F4F8] p-10 sm:p-20">
        <div className="mt-20 text-center">
          <h1 className="text-3xl xs:text-4xl lg:text-[57px]  lg:leading-[4.5rem] font-bold">
            Privacy Policy
          </h1>
        </div>
      </div>
      <div className="mt-20 wrapper">
        <p className="text-lg font-medium">
          At EFG Pay, we value your privacy and are committed to protecting your
          personal information. This Privacy Policy outlines how we collect,
          use, and safeguard your data when you use our services.
          <br />
          By accessing or using EFG Pay, you consent to the practices described
          in this policy.
        </p>

        <div className="mt-10">
          <div className="my-5">
            <h1 className="font-semibold md:text-2xl">
              Information We Collect
            </h1>
            <Content
              header=" Personal Information"
              content=" We collect your name, contact information, and other details
              necessary for account creation and transactions."
            />
            <Content
              header=" Transaction Data"
              content=" We collect information related to your payments,
          purchases, and transactions."
            />
            <Content
              header="Device Information"
              content=" We collect device-specific information to enhance
          user experience and security."
            />
          </div>
          <div className="my-5">
            <h1 className="font-semibold md:text-2xl">
              How We Use Your Information
            </h1>
            <Content
              header="Improve Services"
              content="Your information helps us understand your
          preferences and enhance our services."
            />
            <Content
              header=" Transaction Data"
              content=" We collect information related to your payments,
          purchases, and transactions."
            />
            <Content
              header="Process Transactions"
              content="We use your data to facilitate payments and
          complete transactions."
            />
            <Content
              header="Communication"
              content="We may contact you for account-related information,
          updates, or promotional offers."
            />
            <Content
              header="Analytics"
              content=" We analyze user behavior to improve our app, services, and user
          experience."
            />
            <Content
              header="Data Security"
              content=" We implement strict security measures to protect your data
          from unauthorized access, disclosure, or misuse."
            />
            <Content
              header="Sharing Your Information"
              content="We may share data with trusted partners to
          provide our services, subject to strict confidentiality agreements."
            />
            <Content
              header="Your Choices"
              content="You can update your information, manage
          preferences, and control communication preferences through your
          account settings."
            />
            <Content
              header="Cookies"
              content="We use cookies to enhance
          user experience. You can control cookies through your browser
          settings."
            />
            <Content
              header="Third-Party Link"
              content="Our app may
          contain links to third-party websites. We are not responsible for
          their content or privacy practices."
            />
            <Content
              header="Children's
          Privacy"
              content="Our
          services are not intended for children under 13. We do not knowingly
          collect personal information from minors."
            />
            <Content
              header="Changes to Privacy Policy"
              content="We may update this policy from time to time. Check our website for
          the most current version."
            />
            <Content
              header="Contact Us"
              content="If you have questions or concerns about this policy,
          please contact us at [contact@efgpay.com]."
            />
          </div>
          <p className="my-10 text-xs font-medium text-center">
            {' '}
            By using EFG Pay, you agree to the terms outlined in this Privacy
            Policy. Your privacy matters to us, and we are committed to
            maintaining the highest standards of data protection and security.
          </p>{' '}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
