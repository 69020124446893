import * as Yup from 'yup';

export const contactUsValidationSchema = Yup.object().shape({
  name: Yup.string().required('Please enter your full name'),
  message: Yup.string().required('Please enter your message'),
  phone_number: Yup.string().required('Please enter your phone number'),
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter your email address'),
});
