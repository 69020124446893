import React from 'react';
import Content from '../components/Pages/Content';

const Terms = () => {
  return (
    <div>
      {' '}
      <div className="bg-[#F3F4F8] p-10 sm:p-20">
        <div className="mt-20 text-center">
          <h1 className="text-3xl xs:text-4xl lg:text-[57px]  lg:leading-[4.5rem] font-bold">
            Terms and Conditions
          </h1>
        </div>
      </div>
      <div className="mt-20 wrapper">
        <p className="text-lg font-medium">
          Welcome to EFG Pay! These Terms and Conditions outline the rules and
          regulations for using our services. By accessing or using EFG Pay, you
          agree to comply with these terms.
        </p>

        <div className="mt-10">
          <div className="my-5">
            <Content
              header="Account Registration"
              content="-You must provide accurate and complete information during account registration."
              content2="-You are responsible for maintaining the confidentiality of your account credentials"
            />
            <Content
              header="User Conduct"
              content="-You agree not to use EFG Pay for any unlawful, fraudulent, or malicious activities."
              content2="-You must not use EFG Pay to violate the rights of others or engage in harmful behavior."
            />
            <Content
              header="Payments and Transactions"
              content="You agree to pay the agreed-upon fees for the services used."
              content2="EFG Pay is not responsible for any disputes between users, including merchants and customers"
            />
            <Content
              header="Intellectual Property"
              content="- All content and intellectual property on EFG Pay are protected by copyright and other applicable laws."
            />
            <Content
              header="Privacy"
              content="- We collect and use your data as outlined in our Privacy Policy. By using EFG Pay, you consent to our data practices."
            />
            <Content
              header="Limitation of Liability"
              content="-EFG Pay is not liable for any indirect, consequential, or incidental damages."
            />
            <Content
              header="Termination"
              content="-EFG Pay reserves the right to suspend or terminate your account for violations of these terms."
            />
            <Content
              header="Changes to Terms"
              content="-We may update these terms from time to time. Check our website for the latest version."
            />
            <Content
              header="Governing Law"
              content="-These terms are governed by the laws of [Country/Region]."
            />
            <Content
              header="Contact Us"
              content="-If you have any questions or concerns about these terms, please contact us at [contact@efgpay.com]."
            />
          </div>
          <p className="my-10 text-xs font-medium text-center">
            {' '}
            By using EFG Pay, you agree to the terms and conditions stated
            above. Please read these terms carefully and ensure you understand
            and agree to them before using our services. Your use of EFG Pay
            signifies your acceptance of these terms and your commitment to
            abide by them.
          </p>{' '}
        </div>
      </div>
    </div>
  );
};

export default Terms;
