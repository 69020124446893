import { RouterProvider } from 'react-router-dom';
import './App.scss';
import { routes } from './routes/routes';
import ToastContainer from './components/Base/Toast';
import AppContextProvider from './context/AppContext';

const App = () => {
  return (
    <div className="transition-all ease-out">
      <ToastContainer />
      <AppContextProvider>
        <RouterProvider router={routes} />
      </AppContextProvider>
    </div>
  );
};

export default App;
