import React, { createRef, useEffect, useState } from 'react';
import logo from './../.././src/assets/images/logo.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  BankIcon,
  ChevronDownIcon,
  CodeIcon,
  DashboardIcon,
  MenuIcon,
} from '../assets/icons/icons';
import { useApp } from '../context/AppContext';
import Button from './Base/Button';
import { Transition } from '@headlessui/react';
import useOutsideClick from '../hooks/useOutsideClicks';
import MobileMenu from './MobileMenu';
import { motion } from 'framer-motion';
import products from '../data/products';
const Navigation = () => {
  const { setMobileMenu, mobileMenu } = useApp();
  const navigate = useNavigate();
  const transitionRef = createRef<HTMLDivElement>();
  useOutsideClick(transitionRef, () => setMobileMenu(false));
  const [showDropdown, setShowDropdown] = useState('');
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 300);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMouseEnter = (isProducts?: boolean) => {
    isProducts ? setShowDropdown('products') : setShowDropdown('developers');
  };

  const handleMouseLeave = () => {
    setShowDropdown('');
  };

  const additionalContent = isScrolled ? (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
      className="flex items-center gap-2"
    >
      <Link
        to={'/'}
        className={`px-3 py-3 text-xs font-bold uppercase cursor-pointer xs:px-4 xs:text-sm whitespace-nowrap md:text-base w-fit  text-primary ${
          location.pathname !== '/' ? 'bg-none' : 'bg-primarylight'
        }`}
      >
        for individuals
      </Link>
      <Link
        to={'/business'}
        className={`${
          location.pathname !== '/business' ? 'bg-none' : 'bg-primarylight'
        } px-3 py-3 text-xs font-bold uppercase cursor-pointer xs:px-4 xs:text-sm whitespace-nowrap md:text-base w-fit text-primary`}
      >
        FOR BUSINESSES
      </Link>
    </motion.div>
  ) : null;

  return (
    <>
      <nav className="fixed top-0  w-full bg-white z-[100]">
        {additionalContent}
        <div className="px-4 py-3 lg:px-32 2xl:pr-96 fullflex">
          <div className=" md:w-1/12 fullflex">
            <Link to={'/'}>
              <img src={logo} alt="" />
            </Link>
          </div>
          {showDropdown === 'products' && (
            <motion.div
              onHoverStart={() => handleMouseEnter(true)}
              onHoverEnd={handleMouseLeave}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className={`absolute shadow bg-white p-5 rounded-xl top-10 w-[30rem]  md:left-52 xl:left-96`}
            >
              <div className="flex flex-col">
                {products.map((product, index) => (
                  <Link
                    key={index}
                    to={product.link}
                    className="flex items-center gap-3 p-3 rounded-lg cursor-pointer hover:bg-primarylight"
                  >
                    <div className="flex items-center justify-center w-12 h-12 rounded-2xl bg-primary bg-opacity-10">
                      {product.icon}
                    </div>
                    <div className="w-9/12">
                      <p className="text-sm font-semibold">{product.title}</p>
                      <p className="text-xs">{product.description}</p>
                    </div>
                  </Link>
                ))}
              </div>
            </motion.div>
          )}
          {showDropdown === 'developers' && (
            <motion.div
              onHoverStart={() => handleMouseEnter(false)}
              onHoverEnd={handleMouseLeave}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className={`absolute shadow bg-white p-5 rounded-xl top-14 w-[30rem]  md:left-96 2xl:left-[50rem]`}
            >
              <div className="flex flex-col">
                <div className="w-9/12">
                  <p className="text-sm font-semibold">Coming Soon</p>
                </div>
              </div>
            </motion.div>
          )}
          <div className="hidden w-full xs:w-6/12 xl:w-4/12 sm:block">
            <ul className="flex items-center justify-between text-xs font-semibold uppercase sm:text-sm secondaryfont text-primary">
              <motion.li
                onHoverStart={() => handleMouseEnter(true)}
                onHoverEnd={handleMouseLeave}
                className="flex items-center gap-2 nav-links"
              >
                <span> Products</span>
                <ChevronDownIcon color="#09066B" />
              </motion.li>
              <motion.li
                onHoverStart={() => handleMouseEnter(false)}
                onHoverEnd={handleMouseLeave}
                className="flex items-center gap-2 nav-links"
              >
                Developers
              </motion.li>

              <li className="nav-links">
                <Link to="/about">About</Link>
              </li>
              <li className="nav-links">
                <Link to="/faq">FAQ</Link>
              </li>
            </ul>
          </div>
          <div className="flex items-center gap-3">
            <div className="hidden md:block">
              <Button onClick={() => navigate('/about')}>Learn more</Button>
            </div>
            <div
              className="cursor-pointer sm:hidden"
              onClick={() => setMobileMenu(!mobileMenu)}
            >
              <MenuIcon />
            </div>
          </div>
        </div>

        {mobileMenu && (
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            {<MobileMenu />}
          </motion.div>
        )}
      </nav>
    </>
  );
};

export default Navigation;
