import React from 'react';
import Header from '../components/Pages/Header';
import DownloadApp from '../components/Pages/DownloadApp';
import JoinWaitlist from '../components/Pages/JoinWaitlist';
import PlansForEverybody from '../components/Pages/PlansForEverybody';

const Businesses = () => {
  return (
    <>
      <Header isBusiness />
      <PlansForEverybody />
      <DownloadApp />
      <JoinWaitlist />
    </>
  );
};

export default Businesses;
