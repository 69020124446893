import React from 'react';
import SingleFaq from './SingleFaq';
import FAQS from '../../data/Faq.json';
interface Props {
  title?: string;
  description?: string;
  details?: {
    header: string;
    description: string;
    isRight?: boolean;
  }[];
  product?: string;
}

const ProductDetails = ({ product, title, description, details }: Props) => {
  return (
    <div>
      <div className="text-center">
        <h1 className="font-bold text-3xl xs:text-4xl xs:leading-[4rem] md:text-[57px] text-center ">
          {title}
        </h1>
        <p className="text-lg font-medium md:text-xl md:px-40">{description}</p>
      </div>

      <div className="flex flex-col gap-10 mt-20 wrapper">
        {details?.map((detail, index) => (
          <div
            className={`flex flex-col justify-between ${
              detail.isRight ? 'md:flex-row' : 'md:flex-row-reverse'
            } `}
            key={index}
          >
            <div className="md:w-[40%]">
              <img src={`/product${index + 1}.svg`} alt="" />
            </div>

            <div className="mt-20 md:w-6/12">
              <h4 className="text-2xl font-bold md:text-4xl">
                {detail.header}
              </h4>
              <p className="pt-4 text-lg font-medium md:text-xl lg:pt-8">
                {detail.description}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-20 lg:w-9/12 wrapper">
        <div className="mt-20 text-left">
          <h1 className="text-3xl xs:text-4xl lg:text-[57px]  lg:leading-[4.5rem] font-bold">
            Frequently Asked Questions
          </h1>
          <p className="pt-3 text-base lg:text-lg">
            Everything you need to know about using
            <span className="pl-2 font-medium text-primary">{product}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
