import appstore from './../../assets/images/appstore.svg';
import googleplay from './../../assets/images/googleplay.svg';
const AppButtons = () => {
  return (
    <div className="flex items-center gap-3 md:gap-6">
      <a
        href="https://apps.apple.com/ng/app/efg-pay/id6449555182"
        target="blank"
      >
        <img
          src={appstore}
          alt="appstore icon"
          className="object-contain w-32 cursor-pointer md:w-40"
        />
      </a>
      <a
        target="blank"
        href="https://play.google.com/store/apps/details?id=com.efggroup.efgpay.android"
      >
        <img
          src={googleplay}
          alt="googleplay icon"
          className="object-contain w-32 cursor-pointer md:w-40"
        />
      </a>
    </div>
  );
};

export default AppButtons;
