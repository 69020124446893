import React from 'react';
import { Outlet } from 'react-router-dom';
import Navigation from '../components/Navigation';
import Footer from '../components/Pages/Footer';

interface Props {
  children?: React.ReactNode;
}

const DefaultLayout = ({ children }: Props) => {
  return (
    <>
      <title>EFG Pay: Revolutionizing Payments for Seamless Shopping</title>
      <meta
        name="description"
        content="Experience hassle-free payments with EFG Pay's innovative mobile app. Transform your shopping journey with fast, secure, and intuitive transactions. Explore our platform offering custom solutions for both online and offline merchants"
      />
      <>
        <main className="overflow-hidden">
          <Navigation />
          <div className="">
            <Outlet />
          </div>
          <Footer />
        </main>
      </>
    </>
  );
};

export default DefaultLayout;
