import React from 'react';
import Header from '../../components/Pages/Header';
import ProductDetails from '../../components/Pages/ProductDetails';
import DownloadApp from '../../components/Pages/DownloadApp';
import FAQS from './../../data/Faq.json';
import SingleFaq from '../../components/Pages/SingleFaq';
const ApiSdkProduct = () => {
  const faqs = FAQS.FAQs['For Api'];
  const details = [
    {
      header: 'Seamless Integration',
      description: `EFG Pay's API and SDKs provide hassle-free integration, allowing online merchants to effortlessly incorporate our payment solutions into their platforms. Streamline payment collection and enhance the checkout experience for customers.`,
    },
    {
      header: 'Secure Transactions',
      description: `With EFG Pay's API and SDKs, online merchants can ensure secure and reliable payment processing for their customers. Our advanced encryption safeguards transactions, instilling confidence in every payment made`,
      isRight: true,
    },
    {
      header: 'Customer-Centric Solutions',
      description: `EFG Pay's API and SDKs prioritize user experience. With a user-friendly interface and seamless navigation, customers enjoy a smooth payment journey. Boost customer satisfaction and loyalty with our customer-centric solutions.`,
    },
  ];
  return (
    <section>
      <Header
        isProduct
        productHeader="API and SDKs"
        productDescription="EFG Pay's API and SDKs offer seamless integration for online merchants, enabling smooth and secure collection of payments from customers. Simplify payment processes and elevate your online business to new heights with our innovative solutions"
      />
      <ProductDetails
        title="EFG Pay: Empower Your Online Business"
        description="Empowering Online Merchants with seamless integration to collect payments from customers."
        details={details}
        product="API and SDK's"
      />
      <div className="flex flex-col gap-10 mt-10 wrapper xl:w-7/12 lg:w-9/12">
        {faqs.map((faq, index) => (
          <SingleFaq key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
      <DownloadApp />
    </section>
  );
};

export default ApiSdkProduct;
