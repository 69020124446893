import React from 'react';
import individuals from './../../assets/images/individuals.png';
import businessman from './../../assets/images/businessman.png';
import feature1 from './../../assets/images/feature1.svg';
import feature2 from './../../assets/images/feature2.svg';
import productheaderImage from './../../assets/images/productheader.svg';
import dots from './../../assets/images/dots.svg';

import AppButtons from './AppButtons';
import { Link } from 'react-router-dom';
import JoinWaitlistButton from './JoinWaitlistButton';

interface Props {
  isBusiness?: boolean;
  isProduct?: boolean;
  productHeader?: string;
  productDescription?: string;
}
const Header = ({
  isBusiness,
  isProduct,
  productHeader,
  productDescription,
}: Props) => {
  return (
    <section className="mdlage:mx-[14rem] largest:mx-[30rem] mx-0 flex flex-col justify-between w-full gap-10 px-10 py-32 bg-white md:gap-2 md:flex-row md:px-24 2xl:px-60 md:py-36 ">
      <div className="relative z-50 md:w-8/12 xl:w-3/6">
        {!isProduct && (
          <div className="flex items-center gap-2">
            <Link
              to={'/'}
              className={`px-3 py-3 text-xs font-bold uppercase cursor-pointer xs:px-4 xs:text-sm whitespace-nowrap md:text-base w-fit  text-primary ${
                isBusiness ? 'bg-none' : 'bg-primarylight'
              }`}
            >
              for individuals
            </Link>
            <Link
              to={'/business'}
              className={`${
                !isBusiness ? 'bg-none' : 'bg-primarylight'
              } px-3 py-3 text-xs font-bold uppercase cursor-pointer xs:px-4 xs:text-sm whitespace-nowrap md:text-base w-fit text-primary`}
            >
              FOR BUSINESSES
            </Link>
          </div>
        )}

        {isBusiness ? (
          <div className="mt-10">
            <h1 className="text-3xl xs:text-4xl md:text-[57px]  md:leading-[4rem] font-bold">
              Your Partner For Smooth And Secure
              <span className="block text-primary opacity-80 whitespace-nowrap">
                Merchant Payments
              </span>
            </h1>

            <p className="text-base xs:text-lg md:text-[22px] md:leading-9 font-medium py-5">
              Your smooth & secure payment partner. Effortless transactions that
              boosts your business. Join us now for seamless payments & customer
              satisfaction
            </p>
          </div>
        ) : (
          <div className="mt-10">
            {productHeader === '' ? (
              <h1 className="text-3xl xs:text-4xl md:text-[57px] xs:whitespace-nowrap md:leading-[4rem] font-bold">
                Payment Services for the
                <span className="block text-primary opacity-80 whitespace-nowrap">
                  Modern Customer
                </span>
              </h1>
            ) : (
              <>
                <h1 className="text-3xl xs:text-4xl md:text-[57px]  md:leading-[4rem] font-bold">
                  {productHeader}
                </h1>
              </>
            )}
            <p className="text-base xs:text-lg md:text-[22px] md:leading-9 font-medium py-5">
              {productDescription}
            </p>
          </div>
        )}

        <div className="py-5 i md:py-8">
          {isBusiness ? <JoinWaitlistButton /> : <AppButtons />}
        </div>
      </div>
      <div>
        <div className="relative mdlage:mr-[14rem] largest:mr-[30rem] ">
          {!isProduct ? (
            <div className="relative z-40 px-10 py-6 rounded-lg bg-primarylight w-auto xs:w-[25rem] h-auto xs:h-[28rem] flex items-center">
              <img
                src={isBusiness ? businessman : individuals}
                alt="a man holding a phone with the app"
                className="object-cover w-full "
                loading="lazy"
              />
            </div>
          ) : (
            <img
              src={productheaderImage}
              alt="some icons"
              className="object-cover w-full "
            />
          )}

          {!isProduct && (
            <>
              <div className="absolute z-[48] top-10 md:-right-32 right-0">
                {!isBusiness && (
                  <img
                    src={feature2}
                    alt="a preview of a payment request"
                    className="object-contain w-full hover:animate-pulse animate-pulse md:animate-none"
                  />
                )}
              </div>
              <div className="absolute z-[48] bottom-10 md:-left-32">
                <img
                  src={feature1}
                  alt="a preview of a payment request"
                  className="object-contain w-full md:animate-bounce"
                  loading="lazy"
                />
              </div>
              <div className="absolute -top-4 z-1 -left-16">
                <img src={dots} alt="dots" className="object-contain w-full " />
              </div>
              <div className="absolute -bottom-4 z-1 -right-8">
                <img src={dots} alt="dots" className="object-contain w-full " />
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Header;
