import React from 'react';
import flows from './../../assets/images/flows.svg';
import AppButtons from './AppButtons';
const DownloadApp = () => {
  return (
    <div className="relative mt-20 wrapper">
      <div className="bg-[#F2EEE6] py-10 px-8 md:px-20 rounded-xl flex justify-center">
        <div className="text-center">
          <h1 className="text-xl  relative z-50  md:text-[42px]  md:leading-[4rem] text-center font-bold">
            Your journey to stress free payments begins now.
            <br />
          </h1>{' '}
          <p className="text-lg">Download our App now</p>
          <div className="relative z-50 flex justify-center pt-10">
            <AppButtons />
          </div>
        </div>
      </div>

      <div className="absolute top-0 right-0 z-10">
        <img src={flows} alt="dots" className="object-contain w-full " />
      </div>
    </div>
  );
};

export default DownloadApp;
