import React from 'react';
import payment1 from './../../assets/images/payment1.svg';
import payment2 from './../../assets/images/payment3.svg';
import arrow from './../../assets/images/arrow.svg';

const PaymentSolution = () => {
  return (
    <section className="mt-20 wrapper">
      <div>
        <div>
          <h1 className="font-bold text-3xl xs:text-4xl xs:leading-[4rem] md:text-[57px] text-center ">
            <span className="text-primary">A Payment Solution</span> That Works{' '}
          </h1>
        </div>

        <div className="relative mt-20">
          <div className="relative z-50 flex flex-col-reverse items-center justify-between gap-10 xl:flex-nowrap lg:flex-wrap md:gap-20 md:flex-row">
            <img src={payment1} alt="preview of the app" className="w-auto " />
            <div>
              <p className="text-2xl xs:text-4xl md:text-[57px] xs:whitespace-nowrap md:leading-[4rem] font-bold md:text-right">
                <span className="block text-primary opacity-80">
                  Fund your account
                </span>{' '}
                within seconds
              </p>
              <p className="text-base xs:text-lg lg:text-[20px] md:leading-9 md:text-right py-5">
                Access a diverse range of virtual accounts in various currencies
                - Naira, USD, GBP - and easily top up your wallet
              </p>
            </div>
          </div>
          <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            <img src={arrow} alt="" />
          </div>

          <div className="relative z-50 flex flex-col-reverse items-center justify-between gap-10 mt-40 xl:flex-nowrap lg:flex-wrap md:gap-20 md:flex-row">
            <div>
              <p className="text-2xl xs:text-4xl md:text-[57px] xs:whitespace-nowrap md:leading-[4rem] font-bold text-left">
                <span className="block text-primary opacity-80">
                  Receive & Accept
                </span>{' '}
                Payment Requests
              </p>
              <p className="text-base xs:text-lg md:text-[20px] md:leading-9 text-left py-5">
                Easily receive and accept payment requests from your favorite
                physical or online merchants by simply sharing your personalized
                QR code or Tag.
              </p>
            </div>
            <img src={payment2} alt="preview of the app" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentSolution;
