import { BankIcon, CodeIcon, DashboardIcon } from '../assets/icons/icons';

const products = [
  {
    title: 'Foreign accounts',
    link: '/foreign-accounts',
    icon: <BankIcon />,
    description:
      'Enjoy Free Virtual Accounts in NGN, USD, EUR, GBP to fund your wallet and make hassle-free payments.',
  },
  {
    title: 'API and SDKs',
    link: '/api-sdk',
    icon: <CodeIcon />,
    description:
      'Empowering Online Merchants with seamless integration to collect payments from customers.',
  },
  {
    title: 'Merchants Dashboard',
    link: '/merchants-dashboard',
    icon: <DashboardIcon />,
    description:
      'Access comprehensive dashboard for both Online and Physical merchants, simplifying business operations.',
  },
];

export default products;
