import React from 'react';
import Header from '../components/Pages/Header';
import FeatureCard from '../components/Pages/FeatureCard';
import PaymentSolution from '../components/Pages/PaymentSolution';
import Reviews from '../components/Pages/Reviews';
import DownloadApp from '../components/Pages/DownloadApp';
import longqueues from './../assets/images/longqueues.svg';
import payforgoods from './../assets/images/payforgoods.svg';
import stressfree from './../assets/images/stressfree.svg';
import transactionfailure from './../assets/images/transactionfailure.svg';
const Home = () => {
  return (
    <>
      <Header
        productHeader=""
        productDescription="Transform your shopping experience with your mobile phone for fast, seamless payments. Embrace the future of hassle-free payments with our revolutionary app."
      />
      <div className="grid gap-5 xs:gap-10 xs:grid-cols-2 wrapper mt-">
        <FeatureCard
          color="#D9FFF6"
          image={payforgoods}
          text="Our App, Your shopping Sidekick that redefines how you pay and enables seamless, secure transactions with just a few taps."
        />
        <FeatureCard
          title="Zero Drama Payments"
          color="#FAF0DA"
          image={stressfree}
          text="Uncomplicate Your Transactions with EFG Pay's Awesome, intuitive and secure Platform."
        />
        <FeatureCard
          title="Farewell, Long Lines"
          color="#ECE5FF"
          image={longqueues}
          text="Where Swift Payments Leave Queues in the Dust. Embrace convenience and enjoy hassle-free transactions today"
        />
        <FeatureCard
          title="Fail-Proof Payments Here"
          color="#FFD0E6"
          image={transactionfailure}
          text="Minimize transaction failures, Wave Goodbye to Frustration with a reliable payment system"
        />
      </div>
      <PaymentSolution />
      {/* <Reviews /> */}
      <DownloadApp />
    </>
  );
};

export default Home;
