import React, { useState } from 'react';
import SingleFaq from './SingleFaq';
import FAQS from './../../data/Faq.json';

const AllFaqs = () => {
  const [currentTab, setCurrentTab] = useState('allfaq');
  const faqsForIndividuals = FAQS.FAQs['For Individuals'];
  const faqsForMerchants = FAQS.FAQs['For Merchants'];
  const faqsForBusinesses = FAQS.FAQs['For Businesses'];
  return (
    <div className="wrapper">
      <div>
        <div className="flex flex-col items-start gap-5 sm:gap-10 md:gap-20 md:flex-row">
          <div className="w-full md:w-4/12 lg:w-[20%] py-4 md:border border-gray-400 rounded-xl">
            <div className="flex text-lg md:flex-col whitespace-nowrap">
              <p
                className={`faq  ${currentTab === 'allfaq' ? 'active' : ''} `}
                onClick={() => setCurrentTab('allfaq')}
              >
                FAQs
              </p>
              <p
                className={`faq  ${
                  currentTab === 'merchantfaq' ? 'active' : ''
                } `}
                onClick={() => setCurrentTab('merchantfaq')}
              >
                For Merchants{' '}
              </p>
              <p
                className={`faq  ${
                  currentTab === 'businessfaq' ? 'active' : ''
                } `}
                onClick={() => setCurrentTab('businessfaq')}
              >
                For Online Businesses
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-10 md:w-9/12">
            {currentTab === 'allfaq' &&
              faqsForIndividuals.map((faq, index) => (
                <SingleFaq
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                />
              ))}
            {currentTab === 'merchantfaq' &&
              faqsForMerchants.map((faq, index) => (
                <SingleFaq
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                />
              ))}
            {currentTab === 'businessfaq' &&
              faqsForBusinesses.map((faq, index) => (
                <SingleFaq
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllFaqs;
