import React from 'react';

interface Props {
  label?: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  required?: boolean;
  isPassword?: boolean;
  value?: string;
  name?: string;
}
const TextArea = ({
  required,
  label,
  placeholder,
  onChange,
  isPassword,
  value,
  name,
  ...props
}: Props) => {
  return (
    <div className="relative w-full">
      <div className="flex flex-col space-y-1">
        <label className="text-xs text-left">{label}</label>

        <textarea
          className="w-full px-4 py-3 mb-4 bg-[#F3F4F8] rounded-xl  focus:border-primary focus:outline-none focus:shadow-outline placeholder:text-sm text-dark"
          placeholder={placeholder}
          required={required}
          onChange={onChange}
          value={value}
          {...props}
          name={name}
          id=""
          cols={30}
          rows={3}
        ></textarea>
      </div>
    </div>
  );
};

export default TextArea;
