import axios from 'axios';
import { toast } from 'react-toastify';
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },

  withCredentials: false,
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data && response.data.message) {
      toast.success(`${response.data.message}`);
    }
    return response;
  },
  (error) => {
    if (error.response) {
      toast.error(`${error.response.data.message}`);
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;
