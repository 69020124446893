import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '../../assets/icons/icons';
import { motion } from 'framer-motion';

interface Props {
  question: string;
  answer: string;
}

const SingleFaq = ({ question, answer }: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  const arrowsContainer = {
    closed: { rotate: 0 },
    open: {
      transition: {
        open: { rotate: 360 },
        type: 'spring',
      },
    },
  };

  return (
    <div className="flex flex-col gap-4">
      <motion.div
        initial={{ fontWeight: 'normal' }}
        animate={{ fontWeight: isOpen ? 'bold' : 'normal' }}
        transition={{ duration: 0.2 }}
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-3 cursor-pointer"
      >
        <p className="text-lg font-bold md:text-xl text-primary ">{question}</p>
        {isOpen ? (
          <motion.div
            initial="closed"
            variants={arrowsContainer}
            animate={isOpen ? 'open' : 'closed'}
            transition={{ type: 'spring' }}
          >
            {' '}
            <ChevronDownIcon />
          </motion.div>
        ) : (
          <motion.div animate={{ rotate: 180 }} transition={{ type: 'spring' }}>
            {' '}
            <ChevronUpIcon />
          </motion.div>
        )}
      </motion.div>

      {isOpen && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3 }}
        >
          <p className="md:text-lg text-[#323232]">{answer}</p>
        </motion.div>
      )}
    </div>
  );
};

export default SingleFaq;
