import React, { useState } from 'react';
import PaymentRequestModal from './JoinWaitlistModal';
import Button from '../Base/Button';
import Modal from '../Base/Modal';

const JoinWaitlistButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Join the waitlist</Button>
      <Modal width="w-[50rem]" isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <PaymentRequestModal setIsOpen={() => setIsOpen(false)} />
      </Modal>
    </>
  );
};

export default JoinWaitlistButton;
