import React from 'react';
import logo from './../../assets/images/logo.svg';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <footer className="mt-24 wrapper">
      <div className="border-t border-gray-200">
        <div className="flex flex-col justify-between py-20 md:items-center md:flex-row">
          <div className="md:w-3/6">
            <img src={logo} alt="app logo" />
            <p className="pt-10">Your Gateway to Effortless Payments</p>
            <div className="">
              <div>
                <p className="text-base font-medium">
                  <span className="text-sm font-semibold ">Contact us: </span>
                  <a href="mailto:hello@efgpay.ng" className="hover:opacity-70">
                    hello@efgpay.ng
                  </a>
                </p>
                <p className="text-base font-medium">
                  <span className="text-sm font-semibold ">
                    {' '}
                    Office Address:{' '}
                  </span>{' '}
                  No. 10, Peace Valley Estate, Magodo Phase 2, Lagos.
                </p>
              </div>
            </div>
          </div>

          <div className="flex justify-between md:justify-start md:gap-10 lg:gap-24 i">
            <div className="text-dark">
              <p className="pt-10 text-xl font-bold">Product</p>

              <p className="pt-4">
                <Link to={'/merchants-dashboard'}>Merchants</Link>
              </p>
              <p className="pt-4">
                <Link to={'/foreign-accounts'}>Foreign Accounts</Link>
              </p>
              <p className="pt-4">
                <Link to={'/privacy-policy'}>Privacy Policy</Link>
              </p>
              <p className="pt-4">
                <Link to={'/terms'}>Terms and Conditions</Link>
              </p>
            </div>
            <div className="text-dark">
              <p className="pt-10 text-xl font-bold">Services</p>
              <p className="pt-4">
                {' '}
                <Link to={'/about'}>Contact</Link>
              </p>
              <p className="pt-4">
                {' '}
                <Link to={'/faq'}>FaQ</Link>
              </p>
            </div>
            <div className="text-dark">
              <p className="pt-10 text-xl font-bold">Follow Us</p>
              <p className="pt-4">Facebook</p>
              <p className="pt-4">Instagram</p>
              <p className="pt-4">Twitter</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
